import { IGatsbyImageDataParent } from 'gatsby-plugin-image/dist/src/components/hooks'

interface Image {
  alt: string
  url: string
  gatsbyImageData: IGatsbyImageDataParent
}

export interface NavItem {
  id: string
  text: string
}

interface DatoCmsHeader {
  title: string
  subtitle: string
  nav: NavItem[]
  heroImage: Image
}

interface Audio {
  title: string
  url: string
}

export interface AudioMedia {
  id: string
  media: Audio
}

interface Project {
  id: string
  title: string
  description: string
  cover: Image
  url?: string
  urlText?: string
  urlIcon?: string
}

interface AboutSection {
  text: any
  cover: Image
}

export enum SocialName {
  YouTube = 'youtube',
  Soundcloud = 'soundcloud',
  Facebook = 'facebook',
  Instagram = 'instagram',
  Spotify = 'spotify',
  Email = 'email',
  Linkedin = 'linkedin',
}

interface Social {
  id: string
  name: SocialName
  url: string
}

export interface Contact {
  copyrightText: string
  social: Social[]
  contactForm: string
  credits: any
}

export interface LayoutProps {
  header: DatoCmsHeader
  audioList: AudioMedia[]
  projectList: Project[]
  aboutSection: AboutSection
  contact: Contact
}
