import { SocialName } from 'components/templates/Layout/Layout.d'
import { FC } from 'react'
import {
  IconEmail,
  IconFacebook,
  IconInstagram,
  IconLinkedin,
  IconSoundcloud,
  IconSpotify,
  IconYoutube,
} from '.'
import { IconProps } from './Icon.d'

const Icon: FC<IconProps> = ({ name }) => {
  // https://simpleicons.org/

  switch (name) {
    case SocialName.YouTube:
      return <IconYoutube />

    case SocialName.Soundcloud:
      return <IconSoundcloud />

    case SocialName.Facebook:
      return <IconFacebook />

    case SocialName.Instagram:
      return <IconInstagram />

    case SocialName.Spotify:
      return <IconSpotify />

    case SocialName.Email:
      return <IconEmail />

    case SocialName.Linkedin:
      return <IconLinkedin />

    default:
      return null
  }
}

export default Icon
