import Seo from 'components/atoms/Seo'
import Layout from 'components/templates/Layout'
import { graphql, useStaticQuery } from 'gatsby'

const IndexPage = () => {
  const {
    datoCmsSite,
    site,
    datoCmsHeader,
    datoCmsAudioSection,
    datoCmsProjectSection,
    datoCmsAboutSection,
    datoCmsFooter,
  } = useStaticQuery(query)

  const {
    siteName,
    fallbackSeo: { description },
  } = datoCmsSite.globalSeo
  const { siteUrl } = site.siteMetadata
  const { audioList } = datoCmsAudioSection
  const { projectList } = datoCmsProjectSection
  const { text, cover } = datoCmsAboutSection
  const { social, copyrightText, contactForm, credits } = datoCmsFooter

  return (
    <>
      <Seo
        title={siteName}
        description={description}
        siteUrl={siteUrl}
        lang="it"
      />
      <Layout
        header={datoCmsHeader}
        audioList={audioList}
        projectList={projectList}
        aboutSection={{ text, cover }}
        contact={{ social, copyrightText, contactForm, credits }}
      />
    </>
  )
}

export default IndexPage

export const query = graphql`
  query DatoCMSConenteIt {
    # DatoCMS Seo
    datoCmsSite(locale: { eq: "en" }) {
      globalSeo {
        siteName
        fallbackSeo {
          description
        }
      }
    }

    # Gatsby Config Seo
    site {
      siteMetadata {
        siteUrl
      }
    }

    # Header
    datoCmsHeader(locale: { eq: "en" }) {
      title
      subtitle
      nav {
        id
        text
      }
      heroImage {
        alt
        url
        gatsbyImageData(placeholder: BLURRED)
      }
    }

    # Audio
    datoCmsAudioSection(locale: { eq: "en" }) {
      audioList {
        id
        media {
          url
          title
        }
      }
    }

    # Projects
    datoCmsProjectSection(locale: { eq: "en" }) {
      projectList {
        id
        title
        description
        cover {
          url
          alt
          gatsbyImageData(placeholder: BLURRED)
        }
        url
        urlText
        urlIcon
      }
    }

    # About
    datoCmsAboutSection(locale: { eq: "en" }) {
      text {
        value
      }
      cover {
        url
        alt
        gatsbyImageData(placeholder: BLURRED)
      }
    }

    # Footer
    datoCmsFooter(locale: { eq: "en" }) {
      social {
        id
        url
        name
      }
      contactForm
      copyrightText
      credits {
        value
      }
    }
  }
`
